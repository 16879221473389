.lst-kix_pzsbx3gdqpq0-4>li:before {
    content: "\0025cb  "
}

.lst-kix_pzsbx3gdqpq0-3>li:before {
    content: "\0025cf  "
}

.lst-kix_pzsbx3gdqpq0-5>li:before {
    content: "\0025a0  "
}

.lst-kix_pzsbx3gdqpq0-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_pzsbx3gdqpq0-6 {
    list-style-type: none
}

.lst-kix_pzsbx3gdqpq0-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_pzsbx3gdqpq0-7 {
    list-style-type: none
}

.lst-kix_v8dosusv062r-7>li:before {
    content: "\0025cb  "
}

.lst-kix_pzsbx3gdqpq0-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_pzsbx3gdqpq0-8 {
    list-style-type: none
}

ul.lst-kix_pzsbx3gdqpq0-2 {
    list-style-type: none
}

.lst-kix_pzsbx3gdqpq0-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_pzsbx3gdqpq0-3 {
    list-style-type: none
}

.lst-kix_v8dosusv062r-5>li:before {
    content: "\0025a0  "
}

.lst-kix_v8dosusv062r-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_pzsbx3gdqpq0-4 {
    list-style-type: none
}

ul.lst-kix_pzsbx3gdqpq0-5 {
    list-style-type: none
}

ul.lst-kix_pzsbx3gdqpq0-0 {
    list-style-type: none
}

ul.lst-kix_pzsbx3gdqpq0-1 {
    list-style-type: none
}

.lst-kix_v8dosusv062r-8>li:before {
    content: "\0025a0  "
}

.lst-kix_lpte7vjgnhrp-7>li:before {
    content: "\0025cb  "
}

.lst-kix_lpte7vjgnhrp-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_8odphduysos4-3 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-4 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-1 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-2 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-7 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-8 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-5 {
    list-style-type: none
}

ul.lst-kix_8odphduysos4-6 {
    list-style-type: none
}

.lst-kix_pzsbx3gdqpq0-1>li:before {
    content: "\0025cb  "
}

.lst-kix_pzsbx3gdqpq0-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_v8dosusv062r-0 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-8 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_v8dosusv062r-8 {
    list-style-type: none
}

ul.lst-kix_v8dosusv062r-7 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_v8dosusv062r-6 {
    list-style-type: none
}

ul.lst-kix_v8dosusv062r-5 {
    list-style-type: none
}

ul.lst-kix_v8dosusv062r-4 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_v8dosusv062r-3 {
    list-style-type: none
}

ul.lst-kix_v8dosusv062r-2 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-5>li:before {
    content: "\0025a0  "
}

ul.lst-kix_v8dosusv062r-1 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-8>li:before {
    content: "\0025a0  "
}

.lst-kix_ar9zn78s870h-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_lpte7vjgnhrp-2 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-3 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-0 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-1 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-6 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-7 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-4 {
    list-style-type: none
}

ul.lst-kix_lpte7vjgnhrp-5 {
    list-style-type: none
}

.lst-kix_8odphduysos4-6>li:before {
    content: "\0025cf  "
}

.lst-kix_8odphduysos4-8>li:before {
    content: "\0025a0  "
}

.lst-kix_8odphduysos4-5>li:before {
    content: "\0025a0  "
}

.lst-kix_lpte7vjgnhrp-6>li:before {
    content: "\0025cf  "
}

.lst-kix_lpte7vjgnhrp-5>li:before {
    content: "\0025a0  "
}

.lst-kix_lpte7vjgnhrp-4>li:before {
    content: "\0025cb  "
}

.lst-kix_8odphduysos4-7>li:before {
    content: "\0025cb  "
}

.lst-kix_lpte7vjgnhrp-2>li:before {
    content: "\0025a0  "
}

.lst-kix_v8dosusv062r-3>li:before {
    content: "\0025cf  "
}

.lst-kix_v8dosusv062r-4>li:before {
    content: "\0025cb  "
}

.lst-kix_lpte7vjgnhrp-1>li:before {
    content: "\0025cb  "
}

.lst-kix_lpte7vjgnhrp-3>li:before {
    content: "\0025cf  "
}

.lst-kix_8odphduysos4-0>li:before {
    content: "\0025cf  "
}

.lst-kix_8odphduysos4-1>li:before {
    content: "\0025cb  "
}

.lst-kix_v8dosusv062r-0>li:before {
    content: "\0025cf  "
}

.lst-kix_8odphduysos4-2>li:before {
    content: "\0025a0  "
}

.lst-kix_8odphduysos4-4>li:before {
    content: "\0025cb  "
}

.lst-kix_lpte7vjgnhrp-0>li:before {
    content: "\0025cf  "
}

.lst-kix_v8dosusv062r-1>li:before {
    content: "\0025cb  "
}

.lst-kix_v8dosusv062r-2>li:before {
    content: "\0025a0  "
}

.lst-kix_8odphduysos4-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_lzhw1mi8i63x-8 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-7 {
    list-style-type: none
}

.lst-kix_lzhw1mi8i63x-5>li:before {
    content: "\0025a0  "
}

.lst-kix_lzhw1mi8i63x-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_4fh677x82l86-7 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-8 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-5 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-6 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-3 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-4 {
    list-style-type: none
}

ul.lst-kix_4fh677x82l86-1 {
    list-style-type: none
}

.lst-kix_lzhw1mi8i63x-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_4fh677x82l86-2 {
    list-style-type: none
}

.lst-kix_lzhw1mi8i63x-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_4fh677x82l86-0 {
    list-style-type: none
}

.lst-kix_lzhw1mi8i63x-8>li:before {
    content: "\0025a0  "
}

.lst-kix_lzhw1mi8i63x-3>li:before {
    content: "\0025cf  "
}

.lst-kix_lzhw1mi8i63x-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_lzhw1mi8i63x-4 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-3 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-6 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-5 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-0 {
    list-style-type: none
}

.lst-kix_lzhw1mi8i63x-1>li:before {
    content: "\0025cb  "
}

.lst-kix_ar9zn78s870h-2>li:before {
    content: "\0025a0  "
}

.lst-kix_lzhw1mi8i63x-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_lzhw1mi8i63x-2 {
    list-style-type: none
}

ul.lst-kix_lzhw1mi8i63x-1 {
    list-style-type: none
}

.lst-kix_ar9zn78s870h-0>li:before {
    content: "\0025cf  "
}

.lst-kix_ar9zn78s870h-1>li:before {
    content: "\0025cb  "
}

.lst-kix_4fh677x82l86-0>li:before {
    content: "\0025cf  "
}

.lst-kix_4fh677x82l86-1>li:before {
    content: "\0025cb  "
}

.lst-kix_4fh677x82l86-6>li:before {
    content: "\0025cf  "
}

.lst-kix_4fh677x82l86-4>li:before {
    content: "\0025cb  "
}

.lst-kix_4fh677x82l86-5>li:before {
    content: "\0025a0  "
}

.lst-kix_4fh677x82l86-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_8odphduysos4-0 {
    list-style-type: none
}

.lst-kix_4fh677x82l86-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_ar9zn78s870h-0 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-1 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-2 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-3 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_4fh677x82l86-7>li:before {
    content: "\0025cb  "
}

.lst-kix_4fh677x82l86-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_ar9zn78s870h-8 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-4 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-5 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-6 {
    list-style-type: none
}

ul.lst-kix_ar9zn78s870h-7 {
    list-style-type: none
}

.c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Arial";
    font-style: normal
}

.c6 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c14 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: "Arial";
    font-style: normal
}

.c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Arial";
    font-style: normal
}

.c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial"
}

.c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c10 {
    color: #000000;
    font-weight: 400;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c12 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 12pt 72pt 72pt 72pt
}

.c5 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c3 {
    margin-left: 36pt;
    padding-left: 0pt
}

.c13 {
    padding: 0;
    margin: 0
}

.c15 {
    margin-left: 72pt;
    padding-left: 0pt
}

.c7 {
    font-style: italic
}

.c1 {
    height: 11pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}